import styled from 'styled-components';

import Education from './Education';
import Summary from './Summary';
import Experience from './Experience';
import Project from './Project';
import Certification from './Certification';

const Main = styled.section`
  flex: 1;
  padding: 10px;
  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const MainContainer = () => (
  <Main>
    <Summary />
    <Experience />
    <Project />
    <Education />
    <Certification />
  </Main>
);
export default MainContainer;
