import Wrapper from '../components/Wrapper';
import Item from './Item';

const Info = () => (
  <Wrapper>
    <ul>
      <Item icon="birthday-cake">March 26, 1992</Item>
      <Item icon="male">Male</Item>
      <Item icon="phone" href="tel:+84986173577">
        +84 98 6173 577
      </Item>
      <Item icon="envelope-o" href="mailto:me@nghiep.dev">
        me@nghiep.dev
      </Item>
      {/* <Item icon="map-marker">Tay Hoa District, Phu Yen Province</Item> */}
      <Item icon="github" isBlank href="https://github.com/nghiepdev">
        github.com/nghiepdev
      </Item>
      <Item icon="linkedin" isBlank href="https://www.linkedin.com/in/nghiepit">
        linkedin.com/in/nghiepit
      </Item>
    </ul>
  </Wrapper>
);
export default Info;
