import Title from '../components/Title';
import Item from '../components/Item';
import Detail from './Detail';
import A from '../components/A';

const Experience = () => (
  <div>
    <Title icon="flask">Work Experience</Title>
    <Item title="Aug 2024 - Mar 2025">
      <p>
        Senior Frontend Engineer: <A href="https://recurve.ai">Recurve</A> is a
        growing data and AI infrastructure company founded in late 2023.
      </p>
      <p>
        - Develop the frontend for the AI platform using the Nuxt framework.
      </p>
      <p>
        - Defining scalable folder structures and integrating essential tools to
        optimize development workflows.
      </p>
      <p>
        - Code reviews for team members to ensure adherence to best practices,
        performance standards, and maintainable code quality.
      </p>
    </Item>
    <Item title="Nov 2023 - Feb 2024">
      <p>
        Fronttend Lead: <A href="https://arent.co.jp/en/">Arent</A> focus on
        digital transformation for the Japanese construction industry and
        beyond, the global construction industry.
      </p>
    </Item>
    <Item title="Sep 2019 - Oct 2023">
      <p>
        Software Engineer: <A href="https://tiki.vn">TIKI</A> is the leading
        e-commerce platforms in Vietnam, offering seamless end-to-end retail
        experience.
      </p>
      <p>
        - As a Frontend Engineer at Tiki, I work on building and developing
        internal products for Tiki. See project list in featured projects.
      </p>
      <p>
        - Designed and developed user-friendly website. Contribute ideas for
        UI/UX improvements.
      </p>
      <p>
        - Many new technologies have been applied, clearly document
        descriptions. Development process and deployment are closely bound.
      </p>
      <p>- Working and collaborating with multiple teams and departments.</p>
    </Item>
    <Item title="Oct 2018 - Aug 2019">
      <p>
        Frontend Developer: <A href="https://powersell.vn">Datamart Vietnam</A>{' '}
        is the leading Market Intelligence & Analytics Solutions for Modern
        Retailers. Winner of Startup Vietnam 2018.
      </p>
      <p>
        - Participating in and contributing to the development of the company's
        core system, specifically the Powersell product.
      </p>
      <p>
        - Building a desktop application that connects multiple e-commerce
        platforms and supports real-time chat with customers.
      </p>
    </Item>
    <Item title="Oct 2017 - Sep 2018">
      <p>
        Frontend Developer: <A href="http://elinext.com">eliNext</A> Software
        outsourcing company
      </p>
      <p>
        - Maintain and continue developing the existing system of the client.
      </p>
      <p>- Working on multiple projects and with various clients.</p>
    </Item>
    <Item title="Sep 2016 - Sep 2017">
      <p>
        Fullstack Developer: Digital Agency at{' '}
        <A href="http://flix.com.vn">Flix Vietnam</A>
      </p>
      <p>
        - Participated from the beginning in building the website systems for
        Toyota Brand in Vietnam.
      </p>
    </Item>
    <Item title="Jul 2014 - Aug 2016">
      <p>
        Fullstack Developer: <A href="http://kan-tek.com">Kan-Tek</A> Software
        outsourcing company.
      </p>
      <p>
        - Managed a small team and directly communicated with customers in the
        US regarding their website system build requirements.
      </p>
      <p>- Assigned tasks and evaluated work within the team.</p>
    </Item>
    <Detail />
  </div>
);

export default Experience;
