import Title from '../components/Title';
import Item from '../components/Item';
import A from '../components/A';

const Certification = () => (
  <div>
    <Title icon="certificate">Certifications</Title>
    <Item title="- HackerRank Certification">
      <div
        style={{
          paddingLeft: 12,
        }}
      >
        Problem Solving, JavaScript, React, SQL, CSS, etc <br />
        <A href="https://www.hackerrank.com/nghiepit">View all certificates</A>
      </div>
    </Item>
  </div>
);

export default Certification;
