import styled from 'styled-components';

const Wrapper = styled.section`
  border-top: 1px solid #e0dbdb;
  margin-top: 10px;
`;

const Title = styled.h3`
  text-decoration: underline;
  font-weight: 500;
`;

const Description = styled.div`
  > p {
    font-size: 0.9em;
  }
`;

const Detail = () => (
  <Wrapper>
    <Title>Keywords</Title>
    <Description>
      <p>
        TypeScript, React, Vue.js, Node.js, GraphQL, zod, Swagger/OpenAPI,
        Apollo, Next.js, Nuxt.js, Tailwind CSS, Redux, Mobx, React-Query, REST,
        tRPC, Hook Form, Nexus, ES6+, Sass, Less, CSS-in-JS, PostCSS, SPA, PWA,
        BEM, i18n, Antd, MUI, ChakraIU, MantineUI, NextUI, ElementPlus.
      </p>
      <p>
        Webpack, Gulp, Babel, ESLint, Prettier, Electron, Bot, Firebase, Sentry,
        Postmain, CI/CD, Docker, Rancher, Vercel, Surge, Netlify, Deno, Nginx,
        Github Actions, Yarn, pnpm and open-source.
      </p>
    </Description>
  </Wrapper>
);

export default Detail;
