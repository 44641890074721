import styled from 'styled-components';

import Title from '../components/Title';

const Description = styled.p``;

const Summary = () => (
  <section>
    <Title icon="terminal">Summary</Title>
    <Description>
      Accomplished Frontend Engineer with over 9 years of experience driving
      innovation and delivering high-performance, scalable solutions for web and
      mobile applications. Expertise in architecting sophisticated systems using
      modern frameworks like React, TypeScript, Vue.js, and Next.js,
      complemented by backend proficiency in Node.js. Passionate about crafting
      clean, efficient code and leveraging emerging technologies to solve
      complex challenges.
    </Description>
  </section>
);

export default Summary;
