import styled from 'styled-components';

import Avatar from './Avatar';
import Info from './Info';
import Skill from './Skill';

const Aside = styled.aside`
  width: 265px;
  background: #37474f;
`;

const Sidebar = () => (
  <Aside className="sidebar">
    <Avatar />
    <Info />
    <Skill />
  </Aside>
);

export default Sidebar;
